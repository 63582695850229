<template>
  <div>
    <b-row>
      <b-col
        :md="7"
        class="mt-1 d-flex flex-wrap flex-row"
      >
        <x-date-picker
          v-model="filterModel.from_date"
          :placeholder="$t('Дата начала')"
          class="mr-1 mb-1"
        />
        <x-date-picker
          v-model="filterModel.to_date"
          :placeholder="$t('Дата окончания')"
          class="mr-1 mb-1"
        />
      </b-col>
      <b-col
        :md="5"
        class="mt-1"
      >
        <b-button
          v-can="'contracts-excel-download'"
          variant="success"
          class="btn-tour-skip text-right mb-2 float-right"
          :disabled="xlLoading"
          @click="tableToExcel"
        >
          <feather-icon
            v-if="xlLoading"
            icon="LoaderIcon"
            size="16"
          />
          <feather-icon
            v-else
            icon="DownloadIcon"
            size="16"
          />
          Юклаб олиш
        </b-button>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <good-table-column-search
        v-if="contracts"
        :items="contracts.data"
        :columns="columns"
        :total="contracts.total"
        :page="page"
        :has-show="true"
        model="AutopayClient"
        :filter="filterModel"
        @getItems="getItems"
        @onPageChange="(p) => (page = p)"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >

          <span v-if="props.column.field === 'payment_pin'">
            <span
              class="link"
              @click="goTo('AutopayClient-show', { id: props.row.uni_access_client.id })"
            >{{ props.row.pin }}</span>
          </span>
          <span v-if="props.column.field === 'statusType'">
            <b-badge
              pill
              :variant="props.row.type=='Online'?'light-success':'light-danger'"
            >{{ props.row.type }}</b-badge>
            <p :class="props.row.status=='OK'?'text-success':'text-danger'">{{ props.row.status }}</p>
          </span>
          <span
            v-if="props.column.field === 'autopayListAction'"
            class="d-flex"
          >
            <b-button
              variant="outline-info"
              class="ml-2 btn-tour-skip text-uppercase"
              @click="getInfoTooltip(props.row)"
            >
              <feather-icon
                icon="EyeIcon"
                size="16"
              />
            </b-button>
            <b-overlay v-if="props.row.status!='Cancelled'" :show="loadingBtn">
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="rejectPayment(props.row)"
              >
                <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                />
                {{ $t("Cancel") }}
              </b-button>
            </b-overlay>
          </span>
        </template>
      </good-table-column-search>

      <!-- excel table -->
      <template v-if="xlContracts && xlContracts.data">
        <GoodTableExcel
          v-show="false"
          ref="table"
          class="tablee"
          :items="xlContracts.data"
          :columns="xlColumns"
          :total="xlContracts.total"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'payment_pin'">
              <span
                class="link"
                @click="goTo('AutopayClient-show', { id: props.row.uni_access_client.id })"
              >{{ props.row.pin }}</span>
            </span>
            <span v-if="props.column.field === 'statusType'">
              <b-badge
                pill
                :variant="props.row.type=='Online'?'light-success':'light-danger'"
              >{{ props.row.type }}</b-badge>
              <p :class="props.row.status=='OK'?'text-success':'text-danger'">{{ props.row.status }}</p>
            </span>
          </template>
        </GoodTableExcel>
      </template>
    </b-overlay>

    <!--  -->
    <div v-if="payment_info_modal">
      <b-modal
        v-model="payment_info_modal"
        size="md"
        centered
        title="Доп/Инфо"
        hide-footer
        no-close-on-backdrop
        @close="payment_info_modal = false"
        @cancel="payment_info_modal = false"
      >
        <div>
          <table class="table table-bordered table-striped">
            <tbody>
              <tr>
                <td>Исполнитель</td>
                <td>System</td>
              </tr>
              <tr>
                <td>Отменено пользователем</td>
                <td>-</td>
              </tr>
              <tr>
                <td>Отмененное время</td>
                <td />
              </tr>
              <tr>
                <td>Время_создания</td>
                <td>{{ paymentInfoForModal.uni_access_created_at }}</td>
              </tr>
              <tr>
                <td>ID Транзакции</td>
                <td>{{ paymentInfoForModal.transaction_id }}</td>
              </tr>
              <tr>
                <td>EXT ID</td>
                <td>{{ paymentInfoForModal.ext_id }}</td>
              </tr>
              <tr>
                <td>RRN</td>
                <td>{{ paymentInfoForModal.transaction_id }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import GoodTableExcel from '@/views/table/vue-good-table/GoodTableExcel.vue'
import { showToast } from '@/utils/toast'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
    GoodTableExcel,
  },
  data() {
    return {
      page: 1,
      loading: false,
      xlLoading: false,
      payment_info_modal: false,
      statusTab: 1,
      loadingBtn: false,
      filterModel: {
        per_page: 50,
        // number: null,
        // tab: 'active',
        // appends: '',
        from_date: null,
        to_date: null,
        // client_id: null,
        relations: 'uniAccessClient',
      },

      columns: [],
      contracts: null,
      xlContracts: null,
      //
      uri: 'data:application/vnd.ms-excel;base64,',
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p])
      },
    }
  },
  computed: {
    title() {
      return 'Avto to`lovlar'
    },
    xlColumns() {
      return this.firstCols
    },
    firstCols() {
      return [
      {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('ПИНФЛ'),
          field: 'payment_pin',
          filterField: 'status',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Клиент'),
          field: this.fullnameField,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Номер карты'),
          field: 'card_mask',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Владелец карты'),
          field: 'card_owner',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Сумма'),
          field: 'amount',
          isAmount: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Дата'),
          field: 'uni_access_created_at',
          isDate: true,
        },
        {
          label: this.$t('Тип'),
          field: 'statusType',
          filterField: 'status',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Loan ID'),
          field: 'loan_id',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Действия'),
          field: 'autopayListAction',
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.from_date': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.to_date': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    if (this.isLevier) {
      this.filterModel.status = 1
      this.statusTab = 3
      this.filterModel.is_overdue = true
    }
    if (this.$route.query && this.$route.query.client_id) {
      this.filterModel.client_id = this.$route.query.client_id
    }
    this.filterModel.to_date = this.today()

    this.getItems()
    this.getColumns()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'resource/getAutopayPayments',
      autopayPaymentReject: 'resource/autopayPaymentReject',
    }),
    getColumns() {
      this.columns = [...this.firstCols]
    },

    passportField(row) {
      if (row.client && row.client.doc_series) {
        return row.client.doc_series + row.client.doc_number
      }
      return null
    },

    fullnameField(row) {
      return [
        row.uni_access_client.lastname,
        row.uni_access_client.firstname,
        row.uni_access_client.middlename,
      ].join(' ')
    },

    getItems() {
      this.loading = true
      this.getItemsAction({ ...this.filterModel, page: this.page })
        .then(res => {
          this.contracts = res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getXlItems() {
      this.xlLoading = true

      await this.getItemsAction({ ...this.filterModel, page: this.page, per_page: 9999 })
        .then(res => {
          this.xlContracts = res.data
        })
        .finally(() => {
          this.xlLoading = false
        })
    },
    today() {
      const today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      return `${yyyy}-${mm}-${dd}`
    },

    async tableToExcel(table) {
      await this.getXlItems()

      const tableeee = document.querySelector(
        'div.tablee div.vgt-responsive table',
      )
      const secondRow = tableeee.querySelector('thead tr:nth-child(2)')
      if (secondRow) {
        console.log(secondRow)
        secondRow.remove()
      }
      const rows = tableeee.querySelectorAll('table tr')
      rows.forEach(row => {
        const lastCell = row.lastElementChild
        // const firstCell = row.firstElementChild
        // firstCell.remove()
        if (lastCell.querySelector('td.vgt-left-align svg')) {
          lastCell.remove()
        }
      })
      setTimeout(() => {
        if (!table.nodeType) table = tableeee
        const ctx = { worksheet: `${this.title}`, table: table.innerHTML }
        const link = document.createElement('a')
        let title = `${this.title}(${this.formatDateYmd(
          this.today(),
          'DD.MM.YYYY',
        )})`
        if (this.filterModel.from_date) {
          title = `${this.title} (${this.formatDateYmd(
            this.filterModel.from_date,
            'DD.MM.YYYY',
          )}-${this.formatDateYmd(
            this.filterModel.to_date,
            'DD.MM.YYYY',
          )})`
        }
        link.download = `${title}.xls`
        link.href = this.uri + this.base64(this.format(this.template, ctx))
        link.click()
      }, 10)
    },

    getInfoTooltip(info) {
      this.payment_info_modal = true
      this.paymentInfoForModal = info
    },
    rejectPayment(info) {
      this.$bvModal
        .msgBoxConfirm("Ushbu to'lovni bekor qilmoqchimisiz?", {
          title: 'Tasdiqlang',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Ha',
          cancelTitle: 'Yo`q',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(value => {
          if (value) {
            this.loadingBtn = true
            this.autopayPaymentReject({ id: info.id }).then(res => {
              showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
            }).finally(() => {
              this.loadingBtn = false
            })
          }
        })
    },
  },
}
</script>

<style scoped></style>
